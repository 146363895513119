import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { AiFillHeart } from 'react-icons/ai';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import FramedImage from '@interness/theme-sonora/src/components/FramedImage';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
export const query = graphql`
    query {
        media: directusMediaCollection(name: {eq: "uber-uns"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Wrapper style={{
      textAlign: 'justify'
    }} width={960} mdxType="Wrapper">
      <hr></hr>
  <Heading icon={<AiFillHeart mdxType="AiFillHeart" />} mdxType="Heading">Über uns</Heading>
      <p>{`  Unsere geschäftliche Tätigkeit begann vor vielen Jahren, als Anton Wienken nach einer langen Zeit der Ausbildung und
Wanderjahren in verschiedenen Städten wie Olfen, Münster, der Schweiz und Düsseldorf im Jahr 1965 nach Unna kam. Dort
übernahm er den angesehenen Betrieb "Uhren Beckmann".`}</p>
      <p>{`  Zwei Jahrzehnte später ergab sich die Gelegenheit, direkt nebenan in der Bahnhofstraße 34 unser heutiges Ladengeschäft
zu eröffnen. In diesem historischen Eckhaus begann unsere Geschichte als Juwelier, in dem wir Uhren und Schmuck von
höchster Qualität anbieten.`}</p>
      <p>{`  Im Jahr 1998 kehrte Goldschmiedemeister Christian Wienken nach 13 Jahren des Reisens in den familiären Betrieb zurück,
um ihn am 1. Januar 2000 zu übernehmen. Seitdem hat er das Unternehmen kontinuierlich weiterentwickelt und erweitert.`}</p>
      <p>{`  Heute sind wir stolz darauf, ein Team von fünf engagierten Mitarbeitern zu beschäftigen, darunter auch Vater Anton,
der immer dann gerne aushilft, wenn Kunden historische Uhren mitbringen.`}</p>
      <p>{`  "Wir schätzen die Geschichte und das Erbe, das uns diese Uhren erzählen", sagt Anton Wienken.`}</p>
      <p>{`  Seit 1998 bilden wir junge Talente zu Fachkräften aus und sind besonders stolz auf unser kleines, aber
hochqualifiziertes Team, bestehend aus langjährigen Mitarbeiterinnen und vielversprechendem Nachwuchs. Bei uns
erhalten Sie kompetente Beratung, und Sie werden die Freude und das Engagement spüren, die unser Team für Uhren und
Schmuck hegt.`}</p>
      <p>{`  "Wir können uns jederzeit auf dieses Team verlassen", sagt Christian Wienken stolz. Unser Engagement für erstklassigen
Service und unsere Leidenschaft für Uhrmacherkunst und Schmuck sind die Grundpfeiler unseres Geschäfts, und wir freuen
uns darauf, Sie in unserem Ladenlokal in Unna begrüßen zu dürfen.`}</p>
      <hr></hr>
    </Wrapper>
    <FramedImage maxW='720px' margin='auto' mdxType="FramedImage">
  <Img fluid={props.data.media.media[0].file.localFile.childImageSharp.fluid} mdxType="Img" />
    </FramedImage>
    <Wrapper mdxType="Wrapper">
  <CallToAction mdxType="CallToAction" />
    </Wrapper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      